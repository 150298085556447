var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.catelogList.length)?_c('div',{staticClass:"menus"},[_c('div',{staticClass:"menus_container"},[_c('div',{staticClass:"classifyToggle"},_vm._l((_vm.catelogList),function(item){return _c('div',{key:item.id,class:['_toggle', { 'active': item.id === _vm.curMenuId }],on:{"click":function($event){return _vm.toggleMenu(item)}}},[_vm._v(_vm._s(item.catalog_name))])}),0),_c('vuescroll',{attrs:{"ops":{mode: 'native',  bar: {
      showDelay: 500,
      onlyShowBarOnScroll: true,
      keepShow: false,
      background: '#c1c1c1',
      opacity: .5,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: false,
      size: '6px',
      disable: false,
      }}}},[_c('div',{staticClass:"items"},_vm._l((_vm.subCatelogList),function(item,index){return _c('div',{key:index,class:['item', { 'active': _vm.subCatelogId == item.id}],on:{"click":function($event){return _vm.toggleSubMenu(item)}}},[_vm._v(_vm._s(item.catalog_name))])}),0)])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }