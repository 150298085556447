<!--
 * @Autor: Haibo Deng
 * @Date: 2022-03-31 22:24:48
 * @Description: 详情页
 * @LastEditTime: 2022-05-06 22:11:34
 * @LastEditors: Haibo Deng
-->
<template>
  <div class="page detail">
    <div :class="['wrapper', { 'wrap': type === '4' }]">
      <div class="model-search" v-if="['4'].includes(type)">
        <el-form ref="searchForm" class="search-form" label-position="right" label-width="0"
          :model="searchForm" 
          @keyup.enter.native="getSearchData"
          @submit.native.prevent
        >
          <el-col class="col-l" :span="8">
            <div class="s-logo">
              <img :src="logoPng" alt="">
            </div>
          </el-col>
          <el-col :span="10">
            <el-form-item class="cform-item" label="">
              <el-input placeholder="输入查询内容" class="cinput" v-model="searchForm.pl">
              </el-input>
              <span class="search-icon" @click="getSearchData">
                <img :src="searchPng" alt="">
              </span>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <Menu :catelogList="catalogList" />
      <div class="content">
        <div class="total" v-if="['4'].includes(type)">找到<span class="num">{{total}}</span>条结果</div>
        <div class="search-cont">
          <!-- <div class="title">{{title}}</div> -->
          <div class="box" v-if="['1', '5'].includes(type) && formFields.length">
            <el-form ref="filterForm" class="filter-form" label-position="right" label-width="160px" :model="filterForm">
              <el-col :span="12" v-for="item in formFields" :key="item">
                <el-form-item class="cform-item" :label="item">
                  <el-col :span="11">
                    <el-input placeholder="最小值" class="cinput" v-model="filterForm[`${item}0`]"/>
                  </el-col>
                  <el-col class="sperator" :span="2">~</el-col>
                  <el-col :span="11">
                    <el-input placeholder="最大值" class="cinput" v-model="filterForm[`${item}1`]"/>
                  </el-col>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item class="cform-item btn-item" size="small">
                  <el-button class="reset" @click="reset">重置</el-button>
                  <el-button class="primary" type="primary" @click="onSubmit">确认</el-button>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
        </div>
        <div class="data" v-if="['1', '5'].includes(type)">
        <!-- <div class="title">低噪声放大器</div> -->
          <div class="table" v-if="headerData.length">
            <el-table
              :data="data"
            >
            <el-table-column label="型号" fixed width="140px">
              <template slot-scope="scope">
                <span class="isnew" v-if="scope.row.isnew">NEW</span>
                <br v-if="scope.row.isnew" />
                <span class="text" v-if="!scope.row.pdf_file">{{scope.row.product_num}}</span>
                <a class="link" @click="toPdfUrl(scope.row)" v-else>{{scope.row.product_num}}</a>
              </template>
            </el-table-column>
            <template v-for="hitem in headerData">
              <el-table-column :key="hitem.param_name" :min-width="setColunmWidth(hitem)">
                <template slot="header">
                  <span class="text">{{hitem.param_names[0]}}</span>
                  <br v-if="hitem.param_names[1]" />
                  <span class="text">{{hitem.param_names[1]}}</span>
                </template>
                <template slot-scope="scope">
                  <span>{{scope.row.product_param_display[hitem.param_name]}}</span>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="下载" width="60px">
              <template slot-scope="scope">
                <a class="download" :href="downloadUrl(scope.row)" v-if="scope.row.pdf_file" download>下载</a>
              </template>
            </el-table-column>
          </el-table>
          </div>
        </div>
        <div class="data" v-else>
          <div class="table" v-for="(table, index) in data" :key="index">
            <!-- {{table.catalog.catalog_param}} -->
            <div class="title">{{table.catalog.catalog_name}}</div>
            <el-table
              :data="table.products"
              v-if="table.catalog.catalog_param"
            >
              <el-table-column label="型号" fixed width="140px">
                <template slot-scope="scope">
                  <span class="isnew" v-if="scope.row.isnew">NEW</span>
                  <br v-if="scope.row.isnew" />
                  <span class="text" v-if="!scope.row.pdf_file">{{scope.row.product_num}}</span>
                  <a class="link" @click="toPdfUrl(scope.row)" v-else>{{scope.row.product_num}}</a>
                </template>
              </el-table-column>
              <template v-for="hitem in table.catalog.catalog_param">
                <el-table-column :key="hitem.param_name" :min-width="setColunmWidth(hitem)">
                  <template slot="header">
                    <span class="text">{{hitem.param_names[0]}}</span>
                    <br v-if="hitem.param_names[1]" />
                    <span class="text">{{hitem.param_names[1]}}</span>
                  </template>
                  <template slot-scope="scope">
                    <span>{{scope.row.product_param_display[hitem.param_name]}}</span>
                  </template>
                </el-table-column>
              </template>
              <el-table-column label="下载" width="60px">
                <template slot-scope="scope">
                  <a class="download" :href="downloadUrl(scope.row)" v-if="scope.row.pdf_file" download>下载</a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
import { Api } from "@conf";
import Menu from "@comps/detail/Menu.vue"
export default {
  name: "Detail",
  components: {
    Menu
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API,
      title: '',
      arrowPng: require('@assets/choose.png'),
      checkedPng: require('@assets/checked.png'),
      searchPng: require('@assets/search.png'),
      logoPng: require('@assets/logo_search.png'),
      data: [],
      headerData: [],
      formFields: [],
      filterForm: {},
      searchForm: {
        pl: ''
      },
      type: '1',
      cid: '0', //
      catelogId: '',  //
      catelogName: '',
      subCatelogId: '',
      subCatelogName: '',
      catalogList: [], // 分类列表
      subCatalogList: [], // 子分类列表
      total: 0,
    }
  },
  methods: {
    // 设置列宽
    setColunmWidth(hitem) {
      if (hitem.param_names[0]) {
        const width = hitem.param_names[0].length * 24
        return `${width < 100 ? 100 : width}px`
      }
    },

    // 下载地址
    downloadUrl(item) {
      return `${this.baseUrl}/api/v1/front/pdf/download/${item.id}`
    },
    // 跳转到pdf链接
    toPdfUrl(item) {
      const { id } = item
      window.open(`${process.env.VUE_APP_API}/api/v1/front/pdf/preview/${id}`, "_blank");
    },
    // 选择父级
    handleCatelog (item) {
      this.catelogId = item.id
      this.catelogName = item.catalog_name
      this.subCatalogList.length = 0
      this.subCatalogList.push(...item.subcatalog)

      // 设置子集
      const { id = '0', catalog_name = '', parentid } = item.subcatalog[0]
      this.subCatelogId = id
      this.subCatelogName = catalog_name
      this.getCatalogList({ parentid: this.type === '5' ? '0' : parentid }).then(() => {
        this.reset()
      })
      this.getData()
    },
    // 选择子类
    handleSubCatelog(item) {
      const { id, catalog_name, parentid } = item
      this.subCatelogId = id
      this.subCatelogName = catalog_name
      this.cid = id
      this.title = catalog_name

      if (this.type === '2') {
        this.getSpecialData()
      } else if (this.type === '3') {
        this.getNewData()
      } else if (this.type === '4') {
        this.getSearchData()
      } else {
        this.getCatalogList({ parentid: this.type === '5' ? '0' : parentid }).then(()=>{
          this.reset()
        })
        this.getData()
      }
    },
    // 重置表单
    reset() {
      const filterForm = {}
      this.formFields.forEach( key => {
        filterForm[`${key}0`] = ""
        filterForm[`${key}1`] = ""
      })
      this.filterForm = { ...filterForm}
    },
    // 提交表单
    onSubmit() {
      const params = {}
      this.formFields.forEach( key => {
        const v0 = this.filterForm[`${key}0`] || 0;
        const v1 = this.filterForm[`${key}1`] || 0;
        if (v0 || v1) {
          params[key] = [v0, v1]
        }
      })
      this.getFilterData(params)
    },

    // 获取数据
    getData() {
      const params = {
        cid: this.subCatelogId,
        page: 1,
        pagesize: 500,
      }
      this.data.length = 0
      Api.getDetailData(params).then(res => {
        const { data = [], } = res
        const { page_data } = data
        this.data.push(...page_data)
        // this.specials.push(...specials)
      })
    },
    // 将table th字段 以'（','('换行
    splitTableTh(arr) {
      if (!arr || !arr.length) {
        return []
      }
      arr.forEach(item => {
        if (item.param_name.indexOf('(') > -1) {
          item.param_names = item.param_name.split('(')
          if (item.param_names[1]) {
            item.param_names[1] = '(' + item.param_names[1]
          }
        }else if (item.param_name.indexOf('（') > -1) {
          item.param_names = item.param_name.split('（')
          if (item.param_names[1]) {
            item.param_names[1] = '（' + item.param_names[1]
          }
        } else {
          item.param_names = [item.param_name]
        }
      })
      return [...arr]
    },

    // 分类列表
    getCatalogList(params = {}) {
      return Api.getCatalogList(params).then(res => {
        const { data = [] } = res
        const { page_data } = data
        // 赋初始值
        if (!params.parentid) {
          this.catalogList.push(...page_data)
          let catelogdata = []
          if (this.type !== '5') {
            catelogdata = page_data?.filter(item => item.id == this.cid)
          } else {
            catelogdata = [page_data[0]]
          }

          this.catelogId = catelogdata[0].id || 0 //
          this.catelogName = catelogdata[0].catalog_name
          const subcatalog = catelogdata[0]?.subcatalog
          const { id = '0', catalog_name = '' } = subcatalog[0] || {}
          this.cid = this.type === '5' ? '0' : this.catelogId
          this.subCatelogId = id,
          this.subCatelogName = catalog_name
          this.subCatalogList.push(...subcatalog)
        } else { // 获取表头
          let hdata = {}
          if (this.type === '5') {
            page_data.forEach(item => {
              if (item.id == this.catelogId) {
                hdata = item.subcatalog.filter(item => item.id === this.subCatelogId)[0]
              }
            })
          } else {
            hdata = page_data.filter(item => item.id == this.subCatelogId)[0]
          }
          this.headerData.length = 0
          this.formFields.length = 0
          if (hdata?.catalog_param?.length) {
            const catalog_param = this.splitTableTh(hdata?.catalog_param)
            const { filter_param } = hdata
            this.headerData.push(...catalog_param)
            this.formFields.push(...filter_param)
          }
        }
      })
    },

    // 筛选条件数据
    getFilterData(data = {}) {
      const params = {
        filter_param: JSON.stringify(data),
        cid: this.subCatelogId,
        page: 1,
        pagesize: 500,
      }
      this.data.length = 0
      Api.getFilterData(params).then(res => {
        const { data = [], } = res
        const { page_data } = data
        this.data.push(...page_data)
        // this.specials.push(...specials)
      })
    },
    // 产品专题接口
    getSpecialData() {
      Api.getSpecialData({ sid: this.cid }).then(res => {
        const { data = [], } = res
        data.forEach(item => {
          if (item.catalog?.catalog_param) {
            item.catalog.catalog_param = this.splitTableTh(item.catalog.catalog_param)
          } else {
            item.catalog = {}
          }
        })
        this.data.length = 0
        this.data.push(...data)
      })
    },
    // 获取产品上新数据
    getNewData() {
      this.data.length = 0
      Api.getNewData().then(res => {
        const { data = [], } = res
        data.forEach(item => {
          if (item.catalog?.catalog_param) {
            item.catalog.catalog_param = this.splitTableTh(item.catalog.catalog_param)
          } else {
            item.catalog = {}
          }
        })
        this.data.push(...data)
      })
    },

    // 搜索产品关键词
    getSearchData() {
      this.data.length = 0
      const params = {
        product_num: this.searchForm.pl
      }
      if (!this.searchForm.pl) {
        return false
      }
      Api.getSearchData(params).then(res => {
        const { data = [], } = res
        let total = 0
        data.forEach(item => {
          if (item.catalog?.catalog_param) {
            item.catalog.catalog_param = this.splitTableTh(item.catalog.catalog_param)
          } else {
            item.catalog = {}
          }
          const length = item.products?.length || 0
          total += length
        })
        this.total = total
        this.data.push(...data)
      })
    },
    // 获取专题手册菜单
    getHomeData() {
      Api.getHomeData().then(res => {
        const { data = [] } = res
        const { specials = [] } = data
        specials.forEach(item => item.catalog_name = item.special_name )
        const menus = [
          {
            id: '0',
            catalog_name: "专题手册",
            subcatalog: [...specials]
          }
        ]
        this.catalogList.length = 0
        this.catalogList.push(...menus)
      })
    },
  },
  mounted() {
    const query = this.$route.query;
    const { cid, type, title } = query
    this.type = type;
    this.cid = cid;
    this.title = title || ''

    if (this.type === '1' || this.type === '5') {
      this.getCatalogList().then(() => { // 获取分类
        this.getData()
        this.getCatalogList({ parentid: this.cid }) // 获取表头数据
      });
    }else if (this.type === '2') {
      this.getHomeData()
      this.getSpecialData()
    }else if (this.type === '3') { // 产品上新
      this.getNewData()
    }else if (this.type === '4') { // 产品上新
      this.getSearchData()
    }
  }
}
</script>

<style lang="less" src="./Index.less"></style>
