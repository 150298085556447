<template>
  <div class="menus"  v-if="catelogList.length">
    <div class="menus_container">
      <div class="classifyToggle">
        <div :class="['_toggle', { 'active': item.id === curMenuId }]"
          v-for="item in catelogList" :key="item.id"
          @click="toggleMenu(item)">{{item.catalog_name}}</div>
      </div>
      <vuescroll :ops="{mode: 'native',  bar: {
        showDelay: 500,
        onlyShowBarOnScroll: true,
        keepShow: false,
        background: '#c1c1c1',
        opacity: .5,
        hoverStyle: false,
        specifyBorderRadius: false,
        minSize: false,
        size: '6px',
        disable: false,
        }}"
      >
        <div class="items">
          <div :class="['item', { 'active': subCatelogId == item.id}]"
            v-for="(item,index) in subCatelogList"
            :key="index"
            @click="toggleSubMenu(item)"
          >{{item.catalog_name}}</div>
        </div>
      </vuescroll>
    </div>
  </div>
</template>

<script>
import vuescroll from 'vuescroll';
export default {
  name: "Menu",
  components: {
    vuescroll
  },
  props: {
    catelogList: Array,
  },
  watch: {
    catelogList(n) {
      if (n.length) {
        const query = this.$route.query;
        const { type, cid } = query
        if (['2', '3', '4'].includes(type)) {
          const { subcatalog = [], id } = n[0] || {}
          this.curMenuId = id
          const subMenu = subcatalog.filter(item => item.id == cid)[0] || {}
          this.subCatelogId = subMenu.id
          this.subCatelogList.push(...subcatalog)
        } else if(['1', '5'].includes(type)) {
          let menu = {}
          if (cid === '0') {
            menu = n[0]
          } else {
            menu = n.filter(item => item.id == cid)[0] || {}
          }
          const { subcatalog = [], id } = menu
          this.curMenuId = id
          this.subCatelogList.push(...subcatalog)
          this.subCatelogId = subcatalog[0].id
        }

      }

    }
  },
  data(){
    return {
      curMenuId: '0',
      subCatelogId: '0',
      subCatelogList: []
    }
  },
  methods: {
    toggleMenu(menu) {
      if(menu.id === this.curMenuId) return;
      const { id, subcatalog = [] } = menu || {}
      this.subCatelogList.length = 0
      this.subCatelogList.push(...subcatalog)
      this.subCatelogId = subcatalog[0].id
      this.curMenuId = id
      this.$parent.handleCatelog(menu)
    },
    toggleSubMenu(submenu) {
      const { id } = submenu
      this.subCatelogId = id
      this.$parent.handleSubCatelog(submenu)
    }
  },
}
</script>

<style scoped lang="less">
.menus {

  .scrollWrap{
    height: 270px;
    margin: 0 auto;
    overflow: hidden;
  }
  .menus_container {
    height: calc(100vh - 200px);
    .items {
      padding: 0 56px;
      box-sizing: border-box;
      padding-top: 20px;
      .item {
        cursor: pointer;
        margin-bottom: 3px;

        &.active {
          background: #EDEFF7;
          border-radius: 12px;
          color: #333333;
        }

        box-sizing: border-box;
        padding-left: 12px;
        text-align: left;
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #7C8891;
      }
    }

    //width: 188px;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 42px;

    .classifyToggle {
      overflow: hidden;
      width: 188px;
      margin: 0 auto;
      border-radius: 10px;
      display: flex;
      height: 40px;
      border: 1px solid #E6E7EF;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 14px;
      ._toggle {
        &.active {
          background: #176BB2;
          color: #FFFFFF;
          border-radius: 10px;
        }

        background: #FFFFFF;
        width: 50%;
        flex: 1;
        line-height: 38px;
      }
    }
  }
  max-height: calc(100vh - 186px);
  flex: 0 0 300px;
  margin-right: 24px;
  background-color: #fff;
}
</style>
